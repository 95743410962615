import React, { FC } from 'react';
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { LinkTo } from '../linkTo/LinkTo';
import tokens from './tokens';

export type LinkType = HTMLButtonElement | HTMLAnchorElement;
export type LinkTypes = React.ReactNode | React.PropsWithChildren<any> | string;

// Interface

export interface LinkProps
  extends Partial<Omit<HTMLButtonElement, 'children'>>,
    Partial<
      Omit<
        HTMLAnchorElement,
        'addEventListener' | 'removeEventListener' | 'children'
      >
    > {
  label?: string;
  url?: string;
  icon?: React.ReactNode;
  as?: string;
  onClick?: React.MouseEventHandler<LinkType>;
  target?: string;
  rotateIcon?: boolean;
  a11yTitle?: string;
  /**
   * If true, the icon will be rendered as a circle
   */
  circle?: boolean;
  alignTop?: boolean;
  iconOnRight?: boolean;
  isLoading?: boolean;
}

export interface StyledLinkProps {
  circle?: boolean;
  rotateIcon?: boolean;
  alignTop?: boolean;
  iconOnRight?: boolean;
  hasLabel: boolean;
  isLoading: boolean;
}

export interface StyledIconProps {
  circle?: boolean;
  rotateIcon?: boolean;
}

// Styles

const StyledIcon = styled.span<StyledIconProps>`
  ${({ theme: { space, colors }, circle, rotateIcon }) => css`
    flex-shrink: 0;
    ${circle &&
    css`
      border-radius: 50%;
      border: 2px solid ${colors[tokens.circleBorderColor]};
      padding: ${space.xxSmall};
      background-color: transparent;

      svg {
        flex-shrink: 0;
        transition: all 0.8s ease;
        transform: rotate(${rotateIcon ? '180deg' : '0'});
        path {
          fill: ${colors[tokens.circleBorderColor]};
        }
      }
    `};
  `};
`;

const rotateAni = keyframes`
  0% {
    transform: translatex(-50%) translatey(-50%) rotate(0deg);
  }
  100% {
    transform: translatex(-50%) translatey(-50%) rotate(360deg);
  }
  `;

// Fixes the React does not recognize the `XXX` prop on a DOM element.
const BLACKLISTED_PROPS = [
  'isLoading',
  'as',
  'hasLabel',
  'currentPage',
  'a11yTitle',
  'alignTop',
];

export const StyledLink = styled('a', {
  shouldForwardProp: (propName) =>
    // If not in black list then return true
    !BLACKLISTED_PROPS.includes(propName),
})<StyledLinkProps>`
  ${({
    theme: { space, colors },
    circle,
    alignTop,
    hasLabel,
    iconOnRight,
    isLoading,
  }) => css`
    display: flex;
    align-items: ${alignTop ? 'flex-start' : 'center'};
    flex-direction: ${iconOnRight ? 'row-reverse' : 'row'};
    text-align: left;
    gap: ${hasLabel ? (circle ? space.xSmall : space.xxSmall) : '0'};
    color: currentColor;
    text-decoration: underline;
    flex-shrink: 0;

    ${isLoading &&
    css`
      position: relative;
      transition: all 0.3s linear;

      svg {
        opacity: 0;
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 16px;
        display: inline-block;
        width: ${space.medium};
        height: ${space.medium};
        border: 2px solid;
        border-radius: 50%;
        border-top-color: currentColor;
        border-left-color: currentColor;
        border-right-color: currentColor;
        border-bottom-color: transparent;
        animation: ${rotateAni} 0.6s linear infinite;
        display: block;
      }
    `}

    ${circle &&
    css`
      &:hover {
        ${StyledIcon} {
          background-color: ${colors[tokens.circleHoverBackgroundColor]};
          border-color: ${colors[tokens.circleHoverBorderColor]};
        }
      }
    `};
  `};
`;

const StyledLabel = styled.span<StyledLinkProps>`
  ${({ theme: { space }, alignTop }) => css`
    ${alignTop &&
    css`
      padding-top: ${space.xxxSmall};
    `};
  `};
`;

// JSX

export const Link: FC<LinkProps> = ({
  icon,
  label,
  url,
  as,
  target,
  circle,
  rotateIcon,
  alignTop,
  isLoading,
  iconOnRight,
  a11yTitle,
  onClick,
  ...rest
}) => {
  let component: LinkTypes;

  if (as) {
    component = as;
  } else if (!url) {
    component = 'button';
  } else {
    component = LinkTo;
  }

  // Handle click

  const handleClick = onClick;
  const hasLabel = !!label;

  return (
    <StyledLink
      as={component}
      onClick={handleClick}
      target={target && '_blank'}
      circle={circle}
      aria-label={a11yTitle}
      hasLabel={hasLabel}
      isLoading={isLoading}
      alignTop={alignTop}
      {...(url && { to: url })}
      {...rest}
    >
      {icon && (
        <StyledIcon rotateIcon={rotateIcon} circle={circle}>
          {icon}
        </StyledIcon>
      )}
      {hasLabel && <StyledLabel alignTop={alignTop}>{label}</StyledLabel>}
    </StyledLink>
  );
};

export default Link;
